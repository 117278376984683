.amplify-button[data-variation='primary'] {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; 
  padding-left: 1rem;
  padding-right: 1rem; 
  margin-top: 1.25rem; 
  background-color: #3B82F6; 
  color: #ffffff; 
  font-weight: 700; 
  border-radius: 0.25rem; 
}

.amplify-button[data-variation='primary']:hover {
  background-color: #1D4ED8; 
 }

.amplify-button:hover {
  background-color: #bfdbfe
 }