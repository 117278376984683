.pdfDiv input,
.pdfDiv button {
  font: inherit;
}

.pdfDiv header {
  background-color: #323639;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  padding: 20px;
  color: white;
}

.pdfDiv header h1 {
  font-size: inherit;
  margin: 0;
}

.pdfContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__load {
  margin-top: 1em;
  color: white;
}


.pdfContainerDocument .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdfContainerDocument .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.pdfContainerDocument .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.pdfContainerDocument .react-pdf__message {
  color: white;
}
