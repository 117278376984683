.dx-datagrid-headers .dx-header-row {  
  background-color: whitesmoke;  
  font-weight: bold;
  color: slategray;
}  

.dx-scrollable-container {  
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);  
  width: 100%;  
  height: 100%;  
  overflow-x: auto;  
  overflow-y: hidden;  
  scrollbar-width: none;  
  -ms-overflow-style: none;  
}  

.dx-scrollable-container::-webkit-scrollbar{  
  display: none;  
}