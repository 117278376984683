body {
  background-color: rgb(229 231 235);
  
  margin: 0;
}
/*Estilo del encabezado*/
h1 {
  font-family: 'Raleway';
  color: #FFF;
  font-size: x-large;
}
/*Estilo de la página inferior*/
h2, p, ul {
  font-family: 'Raleway';

  font-size: medium;
}
/*Estilo de los menus*/
ul.header li {
  display: inline;
  list-style-type: none;
  margin: 0;
  font-family: 'Raleway';
  font-size: small;
}
ul.header {
  background-color: rgb(75 85 99);
  padding: 0;
}
ul.header li a {
  color: #FFF;
  font-weight: bold;
  text-decoration: none;
  padding: 15px;
  display: inline-block;
  font-family: 'Raleway';
}
/*Estilo del fondo del contenido*/
.content {
  background-color: rgb(229 231 235);
  padding: 5px;
}
.content h2 {
  padding: 0;
  margin: 0;
}
.content li {
  margin-bottom: 10px;
}

.active {
  background-color: #3b82f6;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 60vh;
}
.botonLogOut {
  border: none;
  padding: 0;
  background: none;
  font-family: 'Raleway';
  color: #FFF;
  font-size: small;  
}