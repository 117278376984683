@tailwind base;
@tailwind components;
@tailwind utilities;

.Modal {
    position: absolute;
    top: 200px;
    left: 40px;
    right: 40px;
    bottom: 40px;
    background-color: transparent;
}

.Overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
}

.loader-style {
    position: absolute;
    top: 40%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}